import Dialog from '@common_dialog';
import cx from 'classnames';
import Button from '@common_button/index';
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';

import CmsRenderer from '@core_modules/cms/components/cms-renderer';

const PrefooterModal = (props) => {
    const {
        open, setOpen, data, loading, error, storeConfig,
    } = props;

    return (
        <>
            {data && !loading && !error ? (
                <Dialog
                    open={open}
                    closeOnBackdrop
                    closeOutsideContent
                    onClose={() => setOpen()}
                    classTitle="!text-2xl !font-bold text-primary leading-6w-full text-center  "
                    classContainer="!max-w-[420px] relative"
                    classWrapperTitle="!rounded-none !pt-5 pb-0 flex-col-reverse"
                    classContent="!rounded-none !px-5 !pb-[37px] !pt-4"
                    classWrapper="!m-0"
                    backdropClassName="!m-0"
                    useCloseTitleButton={false}
                    content={(
                        <>
                            <Button
                                onClick={() => setOpen()}
                                iconOnly
                                className={
                                    cx(
                                        'swift-button-close-dialog',
                                        'absolute top-[5px] right-[-5px]',
                                    )
                                }
                                variant="plain"
                                icon={<XMarkIcon className="h-[24] w-[24]" />}
                            />
                            <div className="dialog-content">
                                <CmsRenderer content={data?.cmsBlocks?.items[0]?.content} storeConfig={storeConfig} />
                            </div>
                        </>
                    )}
                />
            ) : null}
            <style global jsx>
                {`
                    .dialog-content .mgz-row {
                        display: block;
                    }
                `}
            </style>
        </>
    );
};

export default PrefooterModal;
