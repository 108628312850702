/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import dynamic from 'next/dynamic';
import PrefooterModal from '@common/PrefooterPopup/modal';
import { getCmsBlocksLazy } from '@core_modules/theme/services/graphql';

const MagezonText = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonText'));

const index = (props) => {
    const { el_class, storeConfig } = props;

    const classes = el_class.split(' ') || '';
    const identifier = classes[1] || '';

    const [getCmsBlock, { data, loading, error }] = getCmsBlocksLazy();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        getCmsBlock({
            variables: { identifiers: [identifier] },
        });
    };

    return (
        <>
            <PrefooterModal
                open={open}
                setOpen={(isOpen) => setOpen(isOpen)}
                identifier={identifier}
                storeConfig={storeConfig}
                data={data}
                loading={loading}
                error={error}
            />
            <div onClick={handleOpen} className="cursor-pointer action-modal">
                <MagezonText {...props} />
            </div>
        </>
    );
};

export default index;
